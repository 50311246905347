.BaseSelect {
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
  padding-left: 0.3em;
  height: 1.6em;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.BaseSelect__select {
  max-width: 100%;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font: inherit;
  padding: 0;
  margin: 0;
}
.BaseSelect__label {
  display: none;
}
.BaseSelect__arrow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 2.5em;
  font-size: 0.65em;
  line-height: 1em;
  opacity: 0.6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  pointer-events: none;
}
.BaseSelect:hover .BaseSelect__arrow {
  opacity: 1;
}

.BaseTextarea {
  position: relative;
}
.BaseTextarea .BaseTextarea__input {
  background-color: rgba(0, 0, 0, 0.05);
  border: none;
  font: inherit;
  max-width: 100%;
  height: 1.6em;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  resize: vertical;
}
.BaseTextarea .BaseTextarea__input:focus {
  background-color: rgba(0, 0, 0, 0.1);
  outline: none;
}
.BaseTextarea .BaseTextarea__input {
  padding-top: 0.1em;
  padding-left: 0.3em;
  height: 5em;
}

.LuCartBlock {
  position: relative;
}
.LuCartBlock + .LuCartBlock {
  margin-top: -3px;
}

[showborders4] .LuCartRow > * {
  background-color: rgba(0, 0, 255, 0.2);
}
.LuCartRow {
  position: relative;
}
.LuCartRow:last-child {
  border: none;
}
.LuCartRow a.link {
  color: inherit;
  border-bottom: 1px dotted;
}
.LuCartRow a.link:hover {
  border-bottom-style: solid;
}
.LuCartRow a.block {
  color: inherit;
}

.LubokCartView {
  min-height: 100vh;
}
.LubokCartView .requiredIndicator {
  display: inline-block;
  padding-left: 0.2em;
  opacity: 0.7;
}
.LubokCartView .requiredIndicator::before {
  content: "*";
}
.BaseInput--number {
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.BaseInput--number .BaseInput__input {
  width: 2rem;
  text-align: center;
}
.BaseInput--number input[type="number"]::-webkit-outer-spin-button,
.BaseInput--number input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.BaseInput--number input[type="number"] {
  -moz-appearance: textfield;
}
.BaseTextarea,
.BaseInput--text {
  display: block;
}
.BaseTextarea .BaseTextarea__input,
.BaseInput--text .BaseTextarea__input,
.BaseTextarea .BaseInput__input,
.BaseInput--text .BaseInput__input {
  width: 100%;
}


/*# sourceMappingURL=CartView.8ca88ab9.css.map*/