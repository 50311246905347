












































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.LubokCartView {
	min-height: 100vh;

	.requiredIndicator {
		display: inline-block;
		padding-left: 0.2em;
		opacity: 0.7;

		&::before { content: "*"; }
	}
}

.BaseInput {}
.BaseInput--number {
	display: block;
	display: flex;
	justify-content: center;

	.BaseInput__input {
		width: 2rem;
		text-align: center;
	}
	input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
	    -webkit-appearance: none;
	    margin: 0;
	}

	input[type="number"] {
	    -moz-appearance: textfield;
	}
}
.BaseTextarea,
.BaseInput--text {
	display: block;

	.BaseTextarea__input,
	.BaseInput__input {
		width: 100%;
		//text-align: center;
	}
}
