















































































.BaseTextarea {
	position: relative;

	.BaseTextarea__input {
		background-color: fade(black, 5);
		border: none;
		font: inherit;
		max-width: 100%;
		height: 1.6em;
		border-radius: 2px;
		border: 1px solid fade(black, 10);
		resize: vertical;
	}
}
.BaseTextarea {
	.BaseTextarea__input:focus {
		background-color: fade(black, 10);
		outline: none;
	}
}
.BaseTextarea {
	.BaseTextarea__input {
		padding-top: 0.1em;
		padding-left: 0.3em;
		height: 5em;
	}
}
