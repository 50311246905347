







































































@import "../less/vars.less";
@import "../less/mixins.less";

[showborders4] .LuCartRow {
	& > * {
		background-color: fade( blue, 20 );
	}
}

.LuCartRow {
	position: relative;
	// wird ja schon über .grid in atoms.less gesetzt
	//grid-column-gap: @colGap;

	&:last-child {
		border: none;
	}

	a.link {
		color: inherit;
		border-bottom: 1px dotted;
	}
	a.link:hover {
		border-bottom-style: solid;
	}
	a.block {
		color: inherit;
	}
}

@media @mediaQuery__xs {}
@media @mediaQuery__sm {}
@media @mediaQuery__md {}
@media @mediaQuery__dt {}
@media @mediaQuery__lg {}
@media @mediaQuery__xl {}
