
















































































	.BaseSelect {
		position: relative;

		background-color: fade( black, 5 );
		width: 100%;
		padding-left: 0.3em;
		height: 1.6em;
		border-radius: 2px;
		border: 1px solid fade(black, 10);
		cursor: pointer;

		&__select {
			max-width: 100%;
			width: 100%;
			height: 100%;
			cursor: pointer;

			border: none;
			outline: none;
			background-color: transparent;
			-webkit-appearance: none;
    		-moz-appearance: none;
    		appearance: none;
			font: inherit;
			padding: 0;
			margin: 0;
		}
		&__label {
			display: none;
		}
		&__arrow {
			//background-color: fade(red, 60);

			position: absolute;
			top: 0; right: 0;
			bottom: 0;
			width: 2.5em;
			font-size: 0.65em;
			line-height: 1em;
			opacity: 0.6;

			display: flex;
			justify-content: center;
			align-items: center;
			pointer-events: none;
		}

		&:hover &__arrow {
			opacity: 1;
		}
	}
